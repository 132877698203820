.progress {
  visibility: hidden;
}

.notification {
  display: flex;
  align-items: center;
  background: transparent;
  box-shadow: none;
  overflow: visible;
}
